<template>
    <div class="SearchResult">
        <div class="text">
            {{
                searchCur.length
                    ? `搜索“${searchVal}”有以下结果`
                    : `抱歉！没找到“${searchVal}”相关的课程`
            }}
        </div>
        <div class="flex-alc wrap">
            <Curriculum
                v-for="(item, index) in searchCur"
                :key="'cur' + index"
                class="mb-20"
                :imgSize="218"
                :border="6"
                :curriculum="item"
                :class="{ 'mr-20': (index + 1) % 5 != 0 }"
            ></Curriculum>
        </div>
        <div class="text">推荐课程</div>
        <div class="flex-alc wrap mb-170">
            <Curriculum
                v-for="(item, index) in recommendCur"
                :key="'cur' + index"
                class="mb-20"
                :border="6"
                :curriculum="item"
                :imgSize="218"
                :class="{ 'mr-20': (index + 1) % 5 != 0 }"
            ></Curriculum>
        </div>
    </div>
</template>

<script>
    import Curriculum from "@/components/Curriculum.vue";
    export default {
        name: "SearchResult",
        components: {
            Curriculum,
        },
        data() {
            return {
                searchVal: "",
                searchCur: [],
                recommendCur: [],
            };
        },
        watch: {
            $route: function () {
                this.goSearch();
            },
        },
        mounted() {
            this.goSearch();
            this.$https
                .get("/api/cur/list", {
                    page: 1,
                    limit: 5,
                })
                .then((res) => {
                    this.recommendCur = res.data.list;
                });
        },

        methods: {
            goSearch() {
                this.searchVal = this.$route.query.search;
                this.$https
                    .get("/api/cur/list", {
                        page: 1,
                        limit: 999,
                        search: this.searchVal,
                    })
                    .then((res) => {
                        this.searchCur = res.data.list;
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
.SearchResult {
    width: 1200px;
    margin: auto;
    .text {
        font-size: 22px;
        font-weight: 500;
        color: #2d2d2d;
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .mb-170 {
        margin-bottom: 170px;
    }
}
</style>